<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb />
    </div>
    <section class="search__container">
      <common-search :fields="fields" @search="onSearch"></common-search>
    </section>
    <section class="table__container">
      <a-table
        :data-source="tblData"
        :columns="tblColumns"
        :loading="loading"
        :pagination="pagination"
        rowKey="planId"
        @change="onPage"
      >
        <template slot="amount" slot-scope="text, record">
          <span v-if="record.totalAmount !== null">
            {{ record.totalAmount.toFixed(2) }}
          </span>
          <span v-else>--</span>
        </template>
        <template slot="orderSn" slot-scope="text, record">
          <div class="order-sn">
            <div>{{ record.tocOrderSn }}</div>
            <div>{{ record.corporationName }}</div>
          </div>
        </template>
        <template slot="time" slot-scope="text, record">
          {{ record.createTime ? tsFormat(record.createTime) : "-" }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click="onDetail(record.planId)">查看明细</a>
        </template>
      </a-table>
    </section>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import { fetchCorporationInfoByKeyword } from "@/services/OrderManageService";
import { fetchDivisionBillList } from "@/services/FinancialService";
import { tsFormat } from "../../components/DateUtils";
import moment from "moment";

const tblColumns = [
  {
    title: "分账单号",
    dataIndex: "planId",
    width: "25%"
  },
  {
    title: "订单号",
    scopedSlots: { customRender: "orderSn" },
    width: "25%"
  },
  {
    title: "分账金额（元）",
    dataIndex: "totalAmount",
    scopedSlots: { customRender: "amount" },
    width: "10%"
  },
  {
    title: "分账单生成时间",
    scopedSlots: { customRender: "time" },
    width: "20%"
  },
  {
    title: "分账单状态",
    dataIndex: "planStatusValue",
    width: "10%"
  },
  {
    title: "操作",
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      fields: [
        {
          label: "运营商",
          key: "operatorDistributorId",
          component: "j-select-get",
          options: [],
          getOptions: value => {
            return this.onSearchCorporation(value);
          }
        },
        {
          label: "分账单生成时间",
          key: "createStartTime,createEndTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        },
        {
          label: "订单号",
          key: "tocOrderSn",
          component: "j-input"
        }
      ],
      loading: false,
      tblData: [],
      tblColumns,
      pagination: { showQuickJumper: true },
      form: {}
    };
  },
  mounted() {
    this.loadList();
  },
  methods: {
    tsFormat,
    // 获取运营商
    async onSearchCorporation(value) {
      let options = [];
      const resp = await fetchCorporationInfoByKeyword(value);
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          options.push({
            value: item.distributorId,
            label: `${item.corporationName}(${item.distributorTypeValue})`
          });
        });
      }
      return options;
    },
    // 加载列表
    loadList() {
      this.loading = true;
      const params = {
        operatorDistributorId: this.form.operatorDistributorId || "",
        createStartTime: this.form.createStartTime
          ? moment(this.form.createStartTime).format("YYYY-MM-DD 00:00:00")
          : "",
        createEndTime: this.form.createEndTime
          ? moment(this.form.createEndTime).format("YYYY-MM-DD 23:59:59")
          : "",
        tocOrderSn: this.form.tocOrderSn || "",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10
      };
      fetchDivisionBillList(params)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            this.tblData = data.records;
            const pagination = { ...this.pagination };
            pagination.current = data.pageNum;
            pagination.total = data.total;
            pagination.pageSize = data.pageSize;
            pagination.showTotal = function(total, range) {
              return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
            };
            this.pagination = pagination;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.loadList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.loadList();
    },
    // 查看明细
    onDetail(planId) {
      this.$router.push({
        name: "DivisionBillDetail",
        params: {
          planId: planId
        }
      });
    }
  }
};
</script>

<style scoped>
/deep/ .ant-form-item {
  margin-bottom: 0;
}

.order-sn {
  display: flex;
  flex-direction: column;
}

/deep/ .ant-form-item .ant-form-item-label {
  min-width: 110px !important;
}
</style>
